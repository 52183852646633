import React from 'react';
import Layout from '../components/Layout/Layout';
import Header from '../components/Header/Header';
import PageTitle from '../components/PageTitle/PageTitle';
import PageNotFoundImage from '../components/PageNotFoundImage/PageNotFoundImage';
import NotFoundLinks from '../components/NotFoundLinks/NotFoundLinks';
import Footer from '../components/Footer/Footer';

const PageNotFoundComponent = () => (
  <Layout title="Page not found!">
    <Header>
      <PageNotFoundImage />
      <PageTitle
        text="The page you're looking for doesn't exist, or an error occurred. Please check your internet connection or choose a new direction:"
        title="Page not found"
      />
    </Header>
    <NotFoundLinks />
    <Footer />
  </Layout>
);

export default PageNotFoundComponent;

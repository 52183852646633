import React from 'react';
import notFoundImageSrc from '../../images/bg-404.svg';
import { notFoundImg } from './PageNotFound.module.scss';

export default function PageNotFound() {
  return (
    <img
      src={notFoundImageSrc}
      alt="The numbers, 404"
      className={notFoundImg}
    />
  );
}

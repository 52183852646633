import React from 'react';
import BaseLink from '../BaseLink/BaseLink';
import './NotFoundLinks.scss';

export default function NotFoundLinks() {
  return (
    <ul class="not-found-links">
      <li class="not-found-links__link-container">
        <BaseLink href="/">
          Go to home page<span className="not-found-links__link-icon"></span>
        </BaseLink>
      </li>
      <li class="not-found-links__link-container">
        <BaseLink href="/about">
          Learn more about Iterative
          <span className="not-found-links__link-icon"></span>
        </BaseLink>
      </li>
      <li class="not-found-links__link-container">
        <BaseLink href="/pricing">
          Plans and pricing<span className="not-found-links__link-icon"></span>
        </BaseLink>
      </li>
    </ul>
  );
}
